import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import SearchResults from './components/SearchResults';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp'; // Import the SignUp component

function App() {
  const [searchInput, setSearchInput] = useState('');
  const [user, setUser] = useState(localStorage.getItem('username') || ''); // Initialize with localStorage
  const navigate = useNavigate();

  const handleSearch = (input) => {
    setSearchInput(input);
    navigate('/search');
  };

  return (
    <div>
      <Navbar user={user} setUser={setUser} /> {/* Pass user and setUser to Navbar */}
      <Routes>
        <Route path="/" element={<LandingPage onSearch={handleSearch} />} />
        <Route path="/search" element={<SearchResults initialSearch={searchInput} />} />
        <Route path="/signin" element={<SignIn setUser={setUser} />} /> {/* Pass setUser to SignIn */}
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </div>
  );
}

export default function RootApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
