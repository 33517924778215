// SignUp.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { signUp } from '../api'; // Import the signUp function
import './SignIn.css';

const SignUp = () => {
  const [username, setUsername] = useState(''); // State for username
  const [email, setEmail] = useState('');       // State for email
  const [password, setPassword] = useState(''); // State for password
  const [error, setError] = useState(null);     // State for error messages
  const navigate = useNavigate();               // For navigation after sign-up

  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      const formData = {
        uname: username,
        email,
        password,
      };
      const response = await signUp(formData); // Call the signUp API function
      console.log('Signed up successfully:', response);
      navigate('/signin'); // Redirect to sign-in page after successful sign-up
    } catch (err) {
      console.error('Sign-up failed:', err);
      if (err.response && err.response.data && err.response.data.m) {
        setError(err.response.data.m); // Display backend error message
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <Container maxWidth="xs" className="signin-container">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSignUp} style={{ marginTop: '1rem', width: '100%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            name="username"
            autoComplete="username"
            autoFocus
            placeholder="Enter Username"
            InputLabelProps={{ shrink: false }}
            value={username}
            onChange={(e) => setUsername(e.target.value)} // Update state for username
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            placeholder="Enter Email"
            InputLabelProps={{ shrink: false }}
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update state for email
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="new-password"
            placeholder="Enter Password"
            InputLabelProps={{ shrink: false }}
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update state for password
          />
          <Button
            type="submit"
            fullWidth
            sx={{
              marginTop: 2,
              backgroundColor: 'goldenrod',
              color: 'white',
              '&:hover': {
                backgroundColor: 'grey',
              },
            }}
          >
            Sign Up
          </Button>
        </form>

        <Box display="flex" alignItems="center" width="100%" mt={2}>
          <Divider style={{ flexGrow: 1 }} />
          <Typography variant="body2" style={{ margin: '0 10px' }}>
            or
          </Typography>
          <Divider style={{ flexGrow: 1 }} />
        </Box>

        <Typography variant="body2" style={{ marginTop: '10px' }}>
          Already have an account?{' '}
          <Link to="/signin" style={{ color: '#1976d2', textDecoration: 'none' }}>
            Sign In
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUp;
