// SearchResults.js
import React, { useState, useEffect, useCallback } from 'react';
import MapInterface from './MapInterface';
import ResultPopup from './ResultPopup'; // Ensure this component is implemented
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  Switch,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchListings, getFullListing } from '../api'; // Adjust the path to your api.js
import './SearchResults.css'; // Ensure this CSS file exists or adjust accordingly

const SearchResults = ({ initialSearch }) => {
  const [searchInput, setSearchInput] = useState(initialSearch || '');
  const [zoning, setZoning] = useState('');
  const [minAcreage, setMinAcreage] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [marketStatus, setMarketStatus] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showUtilities, setShowUtilities] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  // Define handleSearch before using it
  const handleSearch = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Please log in to search for properties.');
      setSearchError(true);
      return;
    }

    const searchParams = {
      city: searchInput.trim() || undefined,
      zone: zoning || undefined,
      acres_min: minAcreage ? parseFloat(minAcreage) : undefined,
      price_max: maxPrice ? parseFloat(maxPrice) : undefined,
      market_status: marketStatus || undefined,
    };

    try {
      const response = await searchListings(searchParams, token);
      console.log('API Response:', response); // Debugging log
      const results = response || [];
      console.log('Results:', results); // Debugging log

      setFilteredPlaces(Array.isArray(results) ? results : []);
      setSearchError(false);
    } catch (error) {
      console.error('Search failed:', error);
      setFilteredPlaces([]); // Ensure filteredPlaces is an array
      setSearchError(true);
    }
  }, [searchInput, zoning, minAcreage, maxPrice, marketStatus]);

  // useEffect for initial search
  useEffect(() => {
    if (initialSearch && firstTime) {  // Change from fristTime to firstTime
      setSearchInput(initialSearch);
      handleSearch();
      setFirstTime(false);
    }
  }, [initialSearch, handleSearch, firstTime]);

  // Disable scrolling when popup is open
  useEffect(() => {
    if (selectedPlace) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [selectedPlace]);

  // Handle place click to view details
  const handlePlaceClick = async (place) => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Please log in to view property details.');
      return;
    }

    try {
      const fullDetails = await getFullListing({ h: place.h });
      setSelectedPlace({ ...place, ...fullDetails });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert('You need a paid account to view full property details.');
      } else {
        console.error('Failed to get full listing:', error.response?.data || error.message);
      }
    }
  };

  // Close the property details popup
  const handleClosePopup = () => {
    setSelectedPlace(null);
  };

  // Toggle showing utilities on the map
  const handleToggle = (event) => {
    setShowUtilities(event.target.checked);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-results-container">
      <div className="search-bar-container">
        {/* Search Inputs and Filters */}
        <TextField
          placeholder="Enter a city"
          variant="outlined"
          fullWidth
          value={searchInput}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSearchInput(e.target.value)}
          InputProps={{
            style: {
              backgroundColor: 'white',
              borderRadius: '25px',
              paddingRight: '8px',
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: {
              color: 'black',
            },
          }}
        />
        {/* Zoning Filter */}
        <FormControl variant="outlined" style={{ marginLeft: '10px', minWidth: '150px' }}>
          <InputLabel>Zoning</InputLabel>
          <Select
            value={zoning}
            onChange={(e) => setZoning(e.target.value)}
            label="Zoning"
          >
            <MenuItem value="">
              <em>Any</em>
            </MenuItem>
            <MenuItem value="Residential">Residential</MenuItem>
            <MenuItem value="Commercial">Commercial</MenuItem>
            <MenuItem value="Industrial">Industrial</MenuItem>
          </Select>
        </FormControl>
        {/* Min Acreage Filter */}
        <FormControl variant="outlined" style={{ marginLeft: '10px', minWidth: '150px' }}>
          <TextField
            label="Min Acreage"
            variant="outlined"
            type="number"
            value={minAcreage}
            onChange={(e) => setMinAcreage(e.target.value)}
          />
        </FormControl>
        {/* Max Price Filter */}
        <FormControl variant="outlined" style={{ marginLeft: '10px', minWidth: '150px' }}>
          <TextField
            label="Max Price"
            variant="outlined"
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
        </FormControl>
        {/* Market Status Filter */}
        <FormControl variant="outlined" style={{ marginLeft: '10px', minWidth: '150px' }}>
          <InputLabel>Market Status</InputLabel>
          <Select
            value={marketStatus}
            onChange={(e) => setMarketStatus(e.target.value)}
            label="Market Status"
          >
            <MenuItem value="">
              <em>Any</em>
            </MenuItem>
            <MenuItem value="on-market">On Market</MenuItem>
            <MenuItem value="off-market">Off Market</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="content-container">
        <div className="map-container">
          <MapInterface places={filteredPlaces} showUtilities={showUtilities} />
          <div className="utility-toggle">
            <span>Utilities</span>
            <Switch checked={showUtilities} onChange={handleToggle} />
          </div>
        </div>
        <div className="parcels-container">
          <h2>Available Parcels</h2>
          {searchError ? (
            <p>No results found. Please adjust your search criteria.</p>
          ) : filteredPlaces && filteredPlaces.length > 0 ? (
            <ul>
              {filteredPlaces.map((place) => (
                <li
                  key={place.h}
                  onClick={() => handlePlaceClick(place)}
                  style={{ marginBottom: '10px', cursor: 'pointer' }}
                >
                  <h3>{place.Address}</h3>
                  <p>List Price: ${place.List_Price?.toLocaleString()}</p>
                  <p>Acreage: {place.Lot_Acreage}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No results found. Please adjust your search criteria.</p>
          )}
        </div>
      </div>
      {/* Display the property details popup if a place is selected */}
      {selectedPlace && (
        <ResultPopup place={selectedPlace} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default SearchResults;
