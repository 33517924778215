// MapInterface.js
import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from './marker-icon.png'; // Ensure this path is correct

// Custom marker icon
const customIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

const MapInterface = ({ places }) => {
  return (
    <MapContainer
      center={[36.0687, -94.1732]}
      zoom={13}
      style={{ height: '100%', width: '100%' }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {Array.isArray(places) &&
        places.map((place) => (
          place.Latitude && place.Longitude && (
            <Marker
              key={place.h}
              position={[place.Latitude, place.Longitude]}
              icon={customIcon}
            >
              <Popup>
                <strong>{place.Address}</strong> <br />
                List Price: ${place.List_Price?.toLocaleString()} <br />
                Acreage: {place.Lot_Acreage}
              </Popup>
            </Marker>
          )
        ))}
      <MapZoomToFitBounds places={places} />
    </MapContainer>
  );
};

const MapZoomToFitBounds = ({ places }) => {
  const map = useMap();

  useEffect(() => {
    if (Array.isArray(places) && places.length > 0) {
      const bounds = places
        .filter(place => place.Latitude && place.Longitude)
        .map((place) => [place.Latitude, place.Longitude]);
      if (bounds.length > 0) {
        map.fitBounds(bounds);
      }
    }
  }, [places, map]);

  return null;
};

export default MapInterface;
