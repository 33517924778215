import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../api';  // Import the login function
import './SignIn.css';

const SignIn = ({ setUser }) => { // Accept setUser as a prop
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const response = await login(email, password);  // Call the login function

      // Store the token and username
      localStorage.setItem('token', response.tok);
      localStorage.setItem('username', response.uname);

      setUser(response.uname); // Update user state in App.js

      // Navigate to the home page or an appropriate route
      navigate('/');
    } catch (err) {
      setError(err.response?.data?.m || 'An error occurred during login.');
    }
  };

  return (
    <Container maxWidth="xs" className="signin-container">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSignIn} style={{ marginTop: '1rem', width: '100%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            placeholder="Enter Email"
            InputLabelProps={{ shrink: false }}
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update state for email
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            placeholder="Enter Password"
            InputLabelProps={{ shrink: false }}
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update state for password
          />
          <Button
            type="submit"
            fullWidth
            sx={{
              marginTop: 2,
              backgroundColor: 'goldenrod',
              color: 'white',
              '&:hover': {
                backgroundColor: 'grey',
              },
            }}
          >
            Sign In
          </Button>
        </form>

        <Box display="flex" alignItems="center" width="100%" mt={2}>
          <Divider style={{ flexGrow: 1 }} />
          <Typography variant="body2" style={{ margin: '0 10px' }}>
            or
          </Typography>
          <Divider style={{ flexGrow: 1 }} />
        </Box>

        <Typography variant="body2" style={{ marginTop: '10px' }}>
          New to DevelopIQ?{' '}
          <Link to="/signup" style={{ color: '#1976d2', textDecoration: 'none' }}>
            Sign Up
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignIn;
