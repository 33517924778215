import React, { useState } from 'react';
import './ResultPopup.css';
import { MapContainer, TileLayer, Marker, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import { Switch } from '@mui/material'; 
import markerIcon from './marker-icon.png';

// Custom marker icon for the map
const customIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

const ResultPopup = ({ place, onClose }) => {
  const [showUtilities, setShowUtilities] = useState(false);

  const handleToggle = (event) => {
    setShowUtilities(event.target.checked);
  };

  if (!place) return null;

  // Parse the primary photo and alternate photos
  const primaryPhoto = place.Primary_Photo || '';
  const altPhotos = place.Alt_Photos ? place.Alt_Photos.split(', ') : [];
  
  // Fallback to a default image if no primary photo is available
  const fallbackImage = '/path-to-default-image.jpg'; // Set a valid path to a fallback image

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <div className="popup-top">
          <div className="popup-image-section">
            {/* Display the primary photo, or fall back to a default image */}
            <img
              src={primaryPhoto || altPhotos[0] || fallbackImage}
              alt={place.Address || 'Unknown Address'}
              className="popup-image"
              onError={(e) => { e.target.src = fallbackImage; }} // Fallback on error
            />
          </div>
          <div className="popup-map-section">
            <MapContainer
              center={[place.Latitude, place.Longitude]}
              zoom={15}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {showUtilities && (
                <LayerGroup>
                  <TileLayer url="path-to-your-utility-layer/water-lines" />
                  <TileLayer url="path-to-your-utility-layer/sewage-lines" />
                </LayerGroup>
              )}
              <Marker position={[place.Latitude, place.Longitude]} icon={customIcon} />
            </MapContainer>
            <div className="utility-toggle">
              <span>Utilities</span>
              <Switch checked={showUtilities} onChange={handleToggle} />
            </div>
          </div>
        </div>
        <h2 className="popup-price">
          ${place.List_Price !== undefined ? place.List_Price.toLocaleString() : 'N/A'}
        </h2>
        <h3 className="popup-name">{place.Address || 'Unknown Address'}</h3>
        <div className="popup-info">
          <p>Zoning: {place.Zone == null ? place.Zone : 'N/A'}</p>
          <p>Acreage: {place.Lot_Acreage || 'N/A'}</p>
          <p>Market Status: {place.Status || 'N/A'}</p>
        </div>
        <div className="popup-details">
          <div className="info-grid-popup">
            <div className="info-item-popup"><strong>Sewage Access</strong> {place.Dist_to_Sewer_ML ? `${place.Dist_to_Sewer_ML.toFixed(1)} meters` : 'N/A'}</div>
            <div className="info-item-popup"><strong>Water Access</strong> {place.Dist_to_Water_ML ? `${place.Dist_to_Water_ML.toFixed(1)} meters` : 'N/A'}</div>
            <div className="info-item-popup"><strong>Zoning Regulations</strong> {place.Zoning_Regulations || 'N/A'}</div>
            <div className="info-item-popup"><strong>Closest Hospital</strong> {place.Closest_Hospital || 'N/A'}</div>
            <div className="info-item-popup"><strong>Closest School</strong> {place.Closest_school || 'N/A'}</div>
            <div className="info-item-popup"><strong>Floodplains</strong> {place.Floodplains || 'N/A'}</div>
            <div className="info-item-popup"><strong>Incline</strong> {place.Incline || 'N/A'}</div>
            <div className="info-item-popup"><strong>Price Comparison</strong> {place.Price_Per_Acre ? `${place.Price_Per_Acre.toLocaleString()} per acre` : 'N/A'}</div>
            <div className="info-item-popup"><strong>Closest Religious Building</strong> {place.Closest_Religious_Building || 'N/A'}</div>
            <div className="info-item-popup"><strong>Closest Park</strong> {place.Closest_Park || 'N/A'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultPopup;
