import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import './LandingPage.css';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from './marker-icon.png'; 
import ResultPopup from './ResultPopup'; 
import SearchIcon from '@mui/icons-material/Search';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'; // Icon for hospital
import ChurchIcon from '@mui/icons-material/Church'; // Icon for religious building
import SchoolIcon from '@mui/icons-material/School'; // Icon for school
import ParkIcon from '@mui/icons-material/Park'; // Icon for park
import FloodIcon from '@mui/icons-material/WaterDamage'; // Icon for floodplains
import TerrainIcon from '@mui/icons-material/Terrain'; // Icon for incline
import PriceCheckIcon from '@mui/icons-material/PriceCheck'; // Icon for price per sq ft
import WaterIcon from '@mui/icons-material/Opacity'; // Icon for water access
import PlumbingIcon from '@mui/icons-material/Plumbing'; // Icon for sewage access
import GavelIcon from '@mui/icons-material/Gavel'; // Icon for zoning regulations

//searching
const LandingPage = ({ onSearch }) => {
  const [searchInput, setSearchInput] = useState('');
  const [selectedParcel, setSelectedParcel] = useState(null); 

  const handleSearchClick = () => {
    if (searchInput.trim()) {
      onSearch(searchInput);
    }
  };

  const customMarker = new L.Icon({
    iconUrl: markerIcon,
    iconSize: [25, 41], 
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const popularParcels = [
    {
      id: 1,
      name: "123 Oakwood Drive, Fayetteville, AR 72701",
      description: "Prime location in the heart of downtown.",
      coordinates: [36.1627, -86.7816],
      zoning: "Commercial",
      acreage: 150,
      price: 2500000,
      marketStatus: "on-market",
    },
    {
      id: 2,
      name: "101 Maple Lane, Brookside, AR 72702",
      description: "Quiet neighborhood with excellent schools.",
      coordinates: [34.0522, -118.2437],
      zoning: "Residential",
      acreage: 50,
      price: 1500000,
      marketStatus: "off-market",
    },
    {
      id: 3,
      name: "202 Cedar Grove Avenue, Riverbend, AR 72705",
      description: "Perfect for large-scale industrial development.",
      coordinates: [40.7128, -74.0060],
      zoning: "Industrial",
      acreage: 300,
      price: 4500000,
      marketStatus: "on-market",
    },
  ];

  const handleParcelClick = (parcel) => {
    setSelectedParcel(parcel); 
  };

  const handleClosePopup = () => {
    setSelectedParcel(null); 
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  const infoItems = [
    { id: 1, icon: <PlumbingIcon />, text: 'Sewage Access' },
    { id: 2, icon: <WaterIcon />, text: 'Water Access' },
    { id: 3, icon: <GavelIcon />, text: 'Zoning Regulations' },
    { id: 4, icon: <LocalHospitalIcon />, text: 'Closest Hospital' },
    { id: 5, icon: <ChurchIcon />, text: 'Closest Religious Building' },
    { id: 6, icon: <SchoolIcon />, text: 'Closest School' },
    { id: 7, icon: <ParkIcon />, text: 'Closest Park' },
    { id: 8, icon: <FloodIcon />, text: 'Floodplains' },
    { id: 9, icon: <TerrainIcon />, text: 'Topography' },
    { id: 10, icon: <PriceCheckIcon />, text: 'Price Comparison' },
  ];

  return (
    <div className="landing-page">
      <div className="overlay">
        <h1>Find Your Perfect Parcel</h1>
        <p>Explore the best properties in your desired location.</p>
        <div className="search-bar">
          <TextField
            placeholder="Enter an address, zip code, or city"
            variant="outlined"
            fullWidth
            value={searchInput}
            onKeyDown={handleKeyDown}
            onChange={(e) => setSearchInput(e.target.value)}
            InputProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '25px',
                paddingRight: '8px',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchClick}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                color: 'black',
              }
            }}
          />
        </div>
      </div>

      <div className="section popular-searches">
        <h2>Top Rated Parcels</h2>
        <div className="parcels-list">
          {popularParcels.map(parcel => (
            <div key={parcel.id} className="parcel-card" onClick={() => handleParcelClick(parcel)}>
              <MapContainer
                center={parcel.coordinates}
                zoom={13}
                scrollWheelZoom={false}
                style={{ height: "200px", width: "200px" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="&copy; OpenStreetMap contributors"
                />
                <Marker position={parcel.coordinates} icon={customMarker} />
              </MapContainer>
              <div className="parcel-text">
                <h3>{parcel.name}</h3>
                <p>{parcel.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="section about-us">
        <h2>About Us</h2>
        <p>
          At DevelopIQ, we are dedicated to helping developers and real estate professionals find the perfect land for their projects. 
          Our platform offers advanced tools and resources to make the search process easy, efficient, and successful. 
          Whether you're looking for commercial, residential, or industrial land, DevelopIQ is here to support your journey from search to acquisition.
        </p>
      </div>

      <div className="section information-we-offer">
        <h2>Information We Offer</h2>
        <div className="info-grid">
          {infoItems.map((item) => (
            <div key={item.id} className="info-item">
              <div className="info-icon">{item.icon}</div>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>

      {selectedParcel && (
        <ResultPopup place={selectedParcel} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default LandingPage;
